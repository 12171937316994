#root {
  background-image: url('./../public/indian_air_force_jet_fighters.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.logo {
  height: 50px;
  margin-right: 16px;
}

.loading {
  margin-top: 0px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}